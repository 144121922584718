import React, { SVGAttributes } from 'react'

export const ArrowDownIcon: React.FC<SVGAttributes<SVGSVGElement>> = (props) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="#0F1011" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.57757 6.91083C3.90301 6.58539 4.43065 6.58539 4.75609 6.91083L10.0002 12.1549L15.2442 6.91083C15.5697 6.58539 16.0973 6.58539 16.4228 6.91083C16.7482 7.23626 16.7482 7.7639 16.4228 8.08934L10.5894 13.9227C10.264 14.2481 9.73634 14.2481 9.41091 13.9227L3.57757 8.08934C3.25214 7.7639 3.25214 7.23626 3.57757 6.91083Z"
    />
  </svg>
)
