import { useCallback, useEffect, useState } from 'react'

import { getTimeRemaining, Time, TimeRemaining } from '../utils'

export interface UseTimerProps {
  to: Time
  timeout?: number
}

export type UseTimerFn = (props: UseTimerProps) => TimeRemaining

let timer: NodeJS.Timer

export const useTimer: UseTimerFn = (props) => {
  const {
    to,
    timeout = 1000,
  } = props
  const [remained, setRemained] = useState<TimeRemaining>(getTimeRemaining(to))

  const startTimer = useCallback(() => {
    setRemained(getTimeRemaining(to))
  }, [to])

  useEffect(() => {
    startTimer()
    timer = setInterval(startTimer, timeout)
    return () => {
      if (timer) {
        clearInterval(timer)
      }
    }
  }, [to])

  useEffect(() => {
    if (remained.total < 0 && timer) {
      clearInterval(timer)
    }
  }, [remained.total < 0])

  return remained
}
