// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".QB7zk8wjdwXVMlFqnBSz{position:absolute;background-color:#fff;z-index:10;box-sizing:border-box;box-shadow:0 5px 25px rgba(15,16,17,.07);border-radius:0 0 5px 5px;border:1px solid #ced7e0;font-size:14px;line-height:22px}", "",{"version":3,"sources":["webpack://./src/component/Dropdown/Dropdown.module.scss","webpack://./src/assets/styles/_color.scss"],"names":[],"mappings":"AAEA,sBACE,iBAAA,CACA,qBCMW,CDLX,UAAA,CACA,qBAAA,CAEA,wCAAA,CACA,yBAAA,CACA,wBAAA,CAEA,cAAA,CACA,gBAAA","sourcesContent":["@use '../../assets/styles/color';\n\n.select {\n  position: absolute;\n  background-color: color.$white-main;\n  z-index: 10;\n  box-sizing: border-box;\n\n  box-shadow: 0 5px 25px rgba(15, 16, 17, 0.07);\n  border-radius: 0 0 5px 5px;\n  border: 1px solid color.$gray-light;\n\n  font-size: 14px;\n  line-height: 22px;\n}\n","\n$blue-main: #1373E5;\n$blue-hover: #388CF0;\n$blue-light: #EAF1F9;\n$blue-transparent: rgba(19, 115, 229, 0.1);\n\n$gray-main: #87919C;\n$gray-light: #CED7E0;\n\n$dark-main: #0F1011;\n$white-main: #FFFFFF;\n$text-main: #42464B;\n\n$red: #E3405E;\n$green: #10B97B;\n$green-hover: #10BE7D;\n$green-transparent: rgba(16, 185, 123, 0.1);\n$orange: #FABD48;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"select": "QB7zk8wjdwXVMlFqnBSz"
};
export default ___CSS_LOADER_EXPORT___;
