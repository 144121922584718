import React, { forwardRef } from 'react'
import { Field } from '../../types'

import { InputProps, Input } from '../Input'
import { InputWithMask, InputWithMaskProps } from '../InputWithMask'
import { Select, SelectProps, OnSelect } from '../Select'

export type OnChange = (
  value: string | number,
  name?: string
) => void

interface Props{
  type: Field['type']
  handler: Field['handler']
  rules?: Field['rules']
  values: Field['values']
  onChange: OnChange
  placeholder: Field['placeholder']
}

export type InputFieldProps = Props
  & Omit<InputWithMaskProps, 'type' | 'onChange'>
  & Omit<InputProps, 'type' | 'onChange'>
  & Omit<SelectProps, 'type' | 'onChange' | 'options'>

export const InputField = forwardRef<HTMLInputElement, InputFieldProps>(({
  type,
  mask,
  onChange = () => null,
  values,
  ...props
}, ref) => {
  const handlerChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const { value } = e.target
    onChange(value, props.name)
  }

  const handlerSelect: OnSelect = (option) => {
    onChange(option.value, props.name)
  }

  if (type === 'string' && !mask) {
    return <Input
      {...props}
      ref={ref}
      onChange={handlerChange}
    />
  }

  if (type === 'string' && mask) {
    return <InputWithMask
      {...props}
      mask={mask}
      ref={ref}
      onChange={handlerChange}
    />
  }

  if (type === 'date') {
    return <InputWithMask
      {...props}
      mask={mask}
      ref={ref}
      onChange={handlerChange}
    />
  }

  if (type === 'select') {
    return <Select
      {...props}
      ref={ref}
      onSelect={handlerSelect}
      options={Object.entries(values).map(([value, title]) => ({ title, value }))}
    />
  }

  return (
    <Input
      {...props}
      ref={ref}
      onChange={handlerChange}
    />
  )
})
