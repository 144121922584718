import React, { forwardRef } from 'react'
import { cx } from '../../utils'

import styles from './Tabs.module.scss'

type Div = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>

export interface TabOption {
  id: string | number
  text: string | JSX.Element
}

export interface TabsProps extends Div {
  options: TabOption[]
  activeId: TabOption['id']
  onTab: (id: TabOption['id']) => void
}

export const Tabs = forwardRef<HTMLDivElement, TabsProps>(({
  options,
  onTab,
  activeId,
  className,
  ...props
}, ref) => {
  return (
    <div {...props} className={styles.root} ref={ref}>
      {options.map(({ id, text }) => {
        return (
          <div
            key={id}
            className={cx(className, styles.tab, String(activeId) === String(id) ? styles.active : '')}
            onClick={() => onTab(id)}
            onKeyPress={() => onTab(id)}
            role="menuitem"
            tabIndex={0}
          >
            {text}
          </div>
        )
      })}
    </div>
  )
})
