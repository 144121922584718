import React, { CSSProperties, forwardRef } from 'react'
import { cx } from '../../utils'

import styles from './Checkbox.module.scss'

type InputType = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement>

export interface CheckboxProps extends Omit<InputType, 'title' | 'size'>{
  error?: string | JSX.Element | boolean
  title?: string | JSX.Element
  kind?: 'checkbox' | 'radio'
  styleInput?: CSSProperties
}

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(({
  className,
  style,
  styleInput,
  title,
  kind = 'checkbox',
  name,
  ...props
}, ref) => {
  return (
    <div
      className={cx(className, styles.root)}
      style={style}
    >
      <label
        className={styles.label}
      >
        <input
          {...props}
          name={name}
          ref={ref}
          type="checkbox"
        />
        <div
          style={styleInput}
          className={styles.pseudo}
          data-type={kind}
        />
        {title}
      </label>
    </div>
  )
})
