/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from 'react'

import styles from './Timer.module.scss'

import { useTimer, UseTimerProps } from '../../hook'
import { ClockIcon } from '../../icon'
import { cx } from '../../utils'

export interface TimerProps extends UseTimerProps {
  mode?: 'colon' | 'letter'
  visible?: ['s', 'm', 'h', 'd']
  onTimeout?: () => void
  title?: string | JSX.Element
}

export const Timer: React.FC<TimerProps> = ({
  to,
  timeout,
  mode,
  visible = ['s', 'm'],
  onTimeout,
  title,
  children,
}) => {
  const remained = useTimer({
    to,
    timeout,
  })

  useEffect(() => {
    if (remained.total < 0) {
      if (typeof onTimeout === 'function') {
        onTimeout()
      }
    }
  }, [remained.total < 0])

  if (remained.total < 0) {
    return <>{children}</>
  }

  return (
    <div className={styles.wrapper}>
      {title}
      <div className={cx(styles.root)}>
        <ClockIcon />
        {remained.minutes}:{remained.seconds}
      </div>
    </div>
  )
}
