/* eslint-disable react/button-has-type,max-len */
import React from 'react'
import { cx } from '../../utils'

import styles from './Button.module.scss'

type ButtonDefault = React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>

export interface ButtonProps extends ButtonDefault {
  size?: 'big' | 'small'
  variant?: 'contained' | 'outlined' | 'text'
  color?: 'primary' | 'success'
  count?: number | string
  disabled?: boolean
  suffix?: JSX.Element | string
  suffixPosition?: 'right' | 'left'
  loading?: boolean
  fullWidth?: boolean
}

export const Button: React.FC<ButtonProps> = ({
  children,
  type = 'button',
  size = 'big',
  variant = 'contained',
  color = 'primary',
  count,
  disabled,
  suffix,
  suffixPosition = 'left',
  loading,
  className,
  fullWidth,
  ...props
}) => {
  return (
    <button
      type={type}
      disabled={(disabled || loading)}
      className={cx(
        styles.root,
        styles[size],
        styles[variant],
        styles[color],
        count && styles.count,
        fullWidth && styles.fullWidth,
        className,
      )}
      {...props}
    >
      {suffix &&
        <div className={cx(
          styles.suffix,
          suffixPosition === 'left' && styles.suffixL,
          suffixPosition === 'right' && styles.suffixR,
        )}
        >
          {suffix}
        </div>
      }
      {children}
      {count && <span className={styles.counter}>{count}</span>}
    </button>
  )
}
