import React, { CSSProperties, FocusEventHandler, forwardRef, useEffect, useState } from 'react'
import { cx } from '../../utils'

import styles from './Input.module.scss'

type InputType = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement>

export interface InputProps extends Omit<InputType, 'title' | 'size'>{
  error?: string | JSX.Element | boolean
  title?: string | JSX.Element
  suffix?: string | JSX.Element
  size?: 'big' | 'small'
  classNameInput?: string
  styleInput?: CSSProperties
}

export const Input = forwardRef<HTMLInputElement, InputProps>(({
  onFocus,
  onBlur,
  name = Date.now().toFixed(4).toString(),
  title,
  error,
  className,
  classNameInput,
  style,
  styleInput,
  size = 'small',
  suffix,
  ...props
}, ref) => {
  const [isFocus, setFocus] = useState(props.autoFocus)

  useEffect(() => {
    if ((props.value || props.defaultValue) && !isFocus) {
      setFocus(true)
    }
  }, [props.value, props.defaultValue])

  const handlerFocus: FocusEventHandler<HTMLInputElement> = (event) => {
    setFocus(true)
    if (typeof onFocus === 'function') {
      onFocus(event)
    }
  }

  const handleBlur: FocusEventHandler<HTMLInputElement> = (event) => {
    if (!event.target.value) {
      setFocus(false)
    }
    if (typeof onBlur === 'function') {
      onBlur(event)
    }
  }
  return (
    <div
      className={cx(styles.root, className)}
      style={style}
    >
      <label
        className={cx(
          styles.label,
          styles[size],
          isFocus && styles.focus,
          error && styles.error,
        )}
        htmlFor={`field-input-${name}`}
      >
        {title}
      </label>
      {suffix &&
      <div className={styles.suffix}>
        {suffix}
      </div>
      }
      <input
        {...props}
        id={`field-input-${name}`}
        ref={ref}
        placeholder={isFocus ? props.placeholder : ''}
        name={name}
        style={styleInput}
        className={cx(
          classNameInput,
          styles.input,
          styles[size],
          error && styles.error,
        )}
        onFocus={handlerFocus}
        onBlur={handleBlur}
      />
      {error &&
        <div className={styles.message}>
          {error}
        </div>
      }
    </div>
  )
})
