import React, { SVGAttributes } from 'react'

export const CheckIcon: React.FC<SVGAttributes<SVGSVGElement>> = (props) => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="#0F1011" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.1165 3.63329C12.365 3.8818 12.365 4.28472 12.1165 4.53324L5.69978 10.9499C5.58044 11.0692 5.41858 11.1363 5.24981 11.1363C5.08103 11.1363 4.91917 11.0692 4.79983 10.9499L1.88316 8.03324C1.63465 7.78472 1.63465 7.3818 1.88316 7.13329C2.13168 6.88477 2.5346 6.88477 2.78312 7.13329L5.24981 9.59998L11.2165 3.63329C11.465 3.38477 11.8679 3.38477 12.1165 3.63329Z"
    />
  </svg>
)
