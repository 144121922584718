import React, { forwardRef } from 'react'
import MaskedInput, { Props as MaskedInputProps } from 'react-input-mask'

import { Input, InputProps } from '../Input'

export type InputWithMaskProps = Omit<MaskedInputProps, 'inputRef'> & InputProps

export const InputWithMask = forwardRef<HTMLInputElement, InputWithMaskProps>((props, ref) => {
  return (
    // @ts-ignore
    <MaskedInput
      {...props}
    >
      {(props: InputProps) => {
        return (
          <Input {...props} ref={ref} />
        )
      }}
    </MaskedInput>
  )
})
