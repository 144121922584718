import React from 'react'
import {
  BottomSheet as BottomSheetLib,
  BottomSheetProps,
  BottomSheetRef,
} from 'react-spring-bottom-sheet'

import styles from './BottomSheet.module.scss'

import { cx } from '../../utils/cx'

export * from 'react-spring-bottom-sheet'

export const BottomSheet = React.forwardRef<BottomSheetRef, BottomSheetProps>(({
  children,
  className,
  ...props
}, ref) => {
  return (
    <BottomSheetLib
      ref={ref}
      className={cx(styles.root, className)}
      {...props}
    >
      {children}
    </BottomSheetLib>
  )
})
