import dayjs from 'dayjs'

export type Time = number | string | Date
export interface TimeRemaining<T = string> {
  total: number
  days: T
  hours: T
  minutes: T
  seconds: T
}

export type GetTimeRemainingFn = (time: Time) => TimeRemaining

export const getTimeRemaining: GetTimeRemainingFn = (time) => {
  const end = dayjs(time)
  const t = end.diff()
  const s0 = '0'
  const seconds = (s0 + Math.floor((t / 1000) % 60)).slice(-2)
  const minutes = (s0 + Math.floor((t / 1000 / 60) % 60)).slice(-2)
  const hours = (s0 + Math.floor((t / (1000 * 60 * 60)) % 24)).slice(-2)
  const days = (s0 + Math.floor(t / (1000 * 60 * 60 * 24))).slice(-2)
  return {
    total: t,
    days,
    hours,
    minutes,
    seconds,
  }
}
