import React, { SVGAttributes } from 'react'

export const MagnifierIcon: React.FC<SVGAttributes<SVGSVGElement>> = (props) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="#0F1011" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.33333 12.5C10.6345 12.5 12.5 10.6345 12.5 8.33333C12.5 6.03215 10.6345 4.16667 8.33333 4.16667C6.03215 4.16667 4.16667 6.03215 4.16667 8.33333C4.16667 10.6345 6.03215 12.5 8.33333 12.5ZM14.1667 8.33333C14.1667 11.555 11.555 14.1667 8.33333 14.1667C5.11167 14.1667 2.5 11.555 2.5 8.33333C2.5 5.11167 5.11167 2.5 8.33333 2.5C11.555 2.5 14.1667 5.11167 14.1667 8.33333Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.4218 11.4208C11.7472 11.0954 12.2749 11.0954 12.6003 11.4208L17.2569 16.0774C17.5823 16.4028 17.5823 16.9305 17.2569 17.2559C16.9315 17.5814 16.4038 17.5814 16.0784 17.2559L11.4218 12.5993C11.0964 12.2739 11.0964 11.7463 11.4218 11.4208Z"
    />
  </svg>
)
