// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".lpsLy24VRnKOfRHSmSxP{font-family:Montserrat,sans-serif}.lpsLy24VRnKOfRHSmSxP{margin-left:10px;font-weight:600;font-size:14px;line-height:22px;color:#1373e5;display:flex;align-items:center}.lpsLy24VRnKOfRHSmSxP>svg{fill:#1373e5;margin-right:5px}.OnZkhPNmqGi0bIGtfdGi{display:flex;color:#42464b;font-size:14px;line-height:22px;font-family:Montserrat,sans-serif}", "",{"version":3,"sources":["webpack://./src/assets/styles/_root.scss","webpack://./src/assets/styles/_font.scss","webpack://./src/component/Timer/Timer.module.scss","webpack://./src/assets/styles/_color.scss"],"names":[],"mappings":"AAEA,sBACE,iCCHY,CCId,sBACE,gBAAA,CACA,eAAA,CACA,cAAA,CACA,gBAAA,CACA,aCRU,CDSV,YAAA,CACA,kBAAA,CAEA,0BACE,YCbQ,CDcR,gBAAA,CAIJ,sBACE,YAAA,CACA,aCVU,CDWV,cAAA,CACA,gBAAA,CACA,iCDxBY","sourcesContent":["@use './font';\n\n.root {\n  font-family: font.$font-family;\n}\n","$font-family: Montserrat, sans-serif;\n","@use '../../assets/styles/color';\n@use '../../assets/styles/font';\n@import '../../assets/styles/root';\n\n.root {\n  margin-left: 10px;\n  font-weight: 600;\n  font-size: 14px;\n  line-height: 22px;\n  color: color.$blue-main;\n  display: flex;\n  align-items: center;\n\n  & > svg {\n    fill: color.$blue-main;\n    margin-right: 5px;\n  }\n}\n\n.wrapper {\n  display: flex;\n  color: color.$text-main;\n  font-size: 14px;\n  line-height: 22px;\n  font-family: font.$font-family;\n}\n","\n$blue-main: #1373E5;\n$blue-hover: #388CF0;\n$blue-light: #EAF1F9;\n$blue-transparent: rgba(19, 115, 229, 0.1);\n\n$gray-main: #87919C;\n$gray-light: #CED7E0;\n\n$dark-main: #0F1011;\n$white-main: #FFFFFF;\n$text-main: #42464B;\n\n$red: #E3405E;\n$green: #10B97B;\n$green-hover: #10BE7D;\n$green-transparent: rgba(16, 185, 123, 0.1);\n$orange: #FABD48;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "lpsLy24VRnKOfRHSmSxP",
	"wrapper": "OnZkhPNmqGi0bIGtfdGi"
};
export default ___CSS_LOADER_EXPORT___;
