import React from 'react'

import styles from './Tooltip.module.scss'

import { cx } from '../../utils/cx'

interface IProps {
  x: string,
  y: string,
  Target: React.FC,
}

export const Tooltip: React.FC<IProps> = ({
  x,
  y,
  Target,
  children,
}) => {
  return (
    <div className={cx(
      styles.root,
      x === 'right' && styles.right,
      x === 'left' && styles.left,
      x === 'center' && styles.center,
      y === 'top' ? styles.top : styles.bottom,
    )}
    >
      <Target />
      <div className={styles.tooltip}>
        {children}
      </div>
    </div>
  )
}
