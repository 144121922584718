import React, { SVGAttributes } from 'react'

export const ArrowStickRightIcon: React.FC<SVGAttributes<SVGSVGElement>> = (props) => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="#0F1011" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.75 6.99984C1.75 6.67767 2.01117 6.4165 2.33333 6.4165H11.6667C11.9888 6.4165 12.25 6.67767 12.25 6.99984C12.25 7.322 11.9888 7.58317 11.6667 7.58317H2.33333C2.01117 7.58317 1.75 7.322 1.75 6.99984Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.75435 3.08736C7.98216 2.85955 8.3515 2.85955 8.57931 3.08736L12.0793 6.58736C12.3071 6.81516 12.3071 7.18451 12.0793 7.41232L8.57931 10.9123C8.3515 11.1401 7.98216 11.1401 7.75435 10.9123C7.52654 10.6845 7.52654 10.3152 7.75435 10.0874L10.8419 6.99984L7.75435 3.91232C7.52654 3.68451 7.52654 3.31516 7.75435 3.08736Z"
    />
  </svg>

)
