import React, { SVGAttributes } from 'react'

export const ClockIcon: React.FC<SVGAttributes<SVGSVGElement>> = (props) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="#0F1011" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M8.00017 1.3335C6.68162 1.3335 5.39269 1.72449 4.29636 2.45703C3.20004 3.18957 2.34555 4.23077 1.84097 5.44894C1.33638 6.66711 1.20436 8.00756 1.4616 9.30077C1.71883 10.594 2.35377 11.7819 3.28612 12.7142C4.21847 13.6466 5.40636 14.2815 6.69956 14.5387C7.99277 14.796 9.33322 14.6639 10.5514 14.1594C11.7696 13.6548 12.8108 12.8003 13.5433 11.704C14.2758 10.6076 14.6668 9.31871 14.6668 8.00016C14.6668 7.12468 14.4944 6.25778 14.1594 5.44894C13.8243 4.6401 13.3333 3.90517 12.7142 3.28612C12.0952 2.66706 11.3602 2.176 10.5514 1.84097C9.74255 1.50593 8.87565 1.3335 8.00017 1.3335ZM8.00017 13.3335C6.94533 13.3335 5.91419 13.0207 5.03712 12.4347C4.16006 11.8486 3.47648 11.0157 3.07281 10.0411C2.66914 9.0666 2.56352 7.99425 2.76931 6.95968C2.9751 5.92512 3.48305 4.97481 4.22893 4.22893C4.97481 3.48305 5.92512 2.9751 6.95968 2.76931C7.99425 2.56352 9.06661 2.66914 10.0411 3.07281C11.0157 3.47647 11.8486 4.16006 12.4347 5.03712C13.0207 5.91418 13.3335 6.94533 13.3335 8.00016C13.3335 9.41465 12.7716 10.7712 11.7714 11.7714C10.7712 12.7716 9.41465 13.3335 8.00017 13.3335ZM10.0668 8.42016L8.66683 7.6135V4.66683C8.66683 4.49002 8.59659 4.32045 8.47157 4.19543C8.34655 4.0704 8.17698 4.00016 8.00017 4.00016C7.82336 4.00016 7.65379 4.0704 7.52876 4.19543C7.40374 4.32045 7.3335 4.49002 7.3335 4.66683V8.00016C7.3335 8.00016 7.3335 8.0535 7.3335 8.08016C7.33745 8.1261 7.3487 8.17111 7.36683 8.2135C7.38056 8.25305 7.39844 8.29104 7.42017 8.32683C7.43841 8.36472 7.46077 8.40049 7.48683 8.4335L7.5935 8.52016L7.6535 8.58016L9.38683 9.58016C9.48843 9.63775 9.60338 9.66764 9.72017 9.66683C9.86778 9.66786 10.0116 9.61987 10.129 9.53039C10.2464 9.44091 10.3307 9.31499 10.3689 9.17238C10.407 9.02978 10.3967 8.87855 10.3396 8.74242C10.2826 8.60628 10.1819 8.49294 10.0535 8.42016H10.0668Z"
    />
  </svg>

)
