/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { forwardRef, useCallback, useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { cx } from '../../utils/cx'

import styles from './Dropdown.module.scss'

type div = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>

export interface DropdownProps extends div {
  triggerNode: string | HTMLElement | null
  defaultOpen?: boolean
  onOpen?: (isOpen: boolean) => void
  open?: boolean
}

export const Dropdown: React.FC<DropdownProps> = ({
  className,
  triggerNode,
  style,
  children,
  defaultOpen,
  onOpen,
  open: openProps,
  ...props
}) => {
  const [open, setOpen] = useState(defaultOpen)
  const [triggerElement, setTriggerElement] = useState<HTMLElement | null>()

  const handlerOpen = useCallback((e: MouseEvent): void => {
    if (e.target !== triggerElement) {
      setOpen(prev => {
        const value = !prev

        if (typeof onOpen === 'function') {
          onOpen(value)
        }

        return value
      })
    }
  }, [triggerElement])

  useEffect(() => {
    if (open) {
      document.addEventListener('click', handlerOpen)
    } else {
      document.removeEventListener('click', handlerOpen)
    }

    return () => {
      document.removeEventListener('click', handlerOpen)
    }
  }, [open])

  useEffect(() => {
    setOpen(openProps)
  }, [openProps])

  useEffect(() => {
    let triggerElement: HTMLElement | null = null
    if (typeof triggerNode === 'string') {
      triggerElement = document.querySelector(triggerNode) as HTMLElement
    } else if (triggerNode && triggerNode?.offsetParent) {
      triggerElement = triggerNode
    }

    if (triggerElement && triggerElement?.offsetParent) {
      triggerElement.addEventListener('click', handlerOpen)
      setTriggerElement(triggerElement)
    }

    return () => {
      if (triggerElement && triggerElement?.offsetParent) {
        triggerElement.removeEventListener('click', handlerOpen)
      }
    }
  }, [triggerNode])

  return (
    <div
      className={cx(className, styles.select)}
      style={{
        top: triggerElement ? triggerElement.getBoundingClientRect().height : 'none',
        width: triggerElement ? triggerElement.getBoundingClientRect().width : 'none',
        display: open ? 'block' : 'none',
        ...style,
      }}
      {...props}
    >
      {children}
    </div>
  )
}
