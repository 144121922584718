import React from 'react'
import ReactCodesInput, { ReactCodesInputProps } from 'react-codes-input'
import './Code.scss'
import styles from './Code.module.scss'
import { cx } from '../../utils'

export interface CodeProps extends ReactCodesInputProps {
  error?: string | JSX.Element | boolean
  size?: 'big' | 'small'
}

export const Code: React.FC<CodeProps> = ({
  error,
  size = 'big',
  ...props
}) => {
  return (
    <div>
      <ReactCodesInput
        {...props}
        classNameComponent={cx(
          styles.component,
          styles[size],
        )}
        classNameCodeWrapper={styles.codeWrapper}
        classNameCode={cx(styles.code, error && styles.codeError)}
        classNameWrapper={styles.wrapper}
        classNameCodeWrapperFocus={styles.codeWrapperActive}
        classNameEnteredValue={styles.codeEntered}
      />
      {typeof error !== 'boolean' &&
        <div className={styles.error}>{error}</div>
      }
    </div>
  )
}
