import React, { MutableRefObject, useEffect, useRef, useState } from 'react'
import { cx } from '../../utils'

import { Input, InputProps } from '../Input'

import styles from './Select.module.scss'
import { CheckIcon, ArrowDownIcon } from '../../icon'
import { Dropdown } from '../Dropdown'

export interface SelectOption {
  title: string | JSX.Element
  value: string | number
}

export type OnSelect = (option: SelectOption) => void

export interface SelectProps extends Omit<InputProps, 'onSelect'> {
  options: SelectOption[]
  disableStyleInputCursor?: boolean
  onSelect?: OnSelect
}

export const Select: React.FC<SelectProps> = ({
  className,
  defaultValue,
  value: valueProps,
  style,
  size = 'small',
  options,
  styleInput = {},
  classNameInput,
  suffix,
  title,
  onChange,
  disableStyleInputCursor = false,
  onSelect,
  onKeyDown,
  ...props
}) => {
  const [value, setValue] = useState<any>('')
  const [open, setOpen] = useState(false)
  const [activeSuggestion, setActiveSuggestion] = useState<number>(0)

  const [inputElement, setInputElement] = useState<HTMLInputElement | null>(null)

  const refInput = useRef() as MutableRefObject<HTMLInputElement>

  const handlerSelect = (option: SelectOption): void => {
    setValue(option.title)
    if (typeof onSelect === 'function') {
      onSelect(option)
    }
  }

  if (!disableStyleInputCursor) {
    styleInput = { ...styleInput, color: 'transparent', textShadow: '0 0 0 black' }
  }

  useEffect(() => {
    if (refInput.current) {
      setInputElement(refInput.current)
    }
  }, [refInput.current])

  useEffect(() => {
    const option = options.find(({ value }) => value === valueProps || value === defaultValue)
    if (option) {
      setValue(option.title)
    }
  }, [valueProps, defaultValue])

  const handlerKeyDown = (e: any): void => {
    if (e.keyCode === 13) {
      setValue(options[activeSuggestion].title)
      setOpen(false)
    } else if (e.keyCode === 38) {
      if (activeSuggestion === 0) {
        return
      }

      setActiveSuggestion(prev => prev - 1)
    } else if (e.keyCode === 40) {
      if (activeSuggestion - 1 === options.length) {
        return
      }

      setActiveSuggestion(prev => prev + 1)
    }
    if (typeof onKeyDown === 'function') {
      onKeyDown(e)
    }
  }

  return (
    <div className={[className, styles.root].join(' ')} style={style}>
      <Input
        {...props}
        ref={refInput}
        value={value || ''}
        onKeyDown={handlerKeyDown}
        onChange={onChange || (() => null)}
        suffix={suffix || <ArrowDownIcon className={cx(styles.arrow, open && styles.open)} />}
        title={title}
        size={size}
        classNameInput={cx(classNameInput, open && styles.input)}
        styleInput={styleInput}
      />
      <Dropdown
        triggerNode={inputElement}
        onOpen={(open) => setOpen(open)}
        open={open}
      >
        {options.map((item, idx) => (
          <div
            key={item.value}
            onClick={() => handlerSelect(item)}
            className={cx(
              styles.item,
              styles[size],
              ((item.value === value) || (idx === activeSuggestion)) && styles.active,
            )}
          >
            {item.title}

            {item.value === value &&
              <CheckIcon className={styles.check} />
            }
          </div>
        ))}
      </Dropdown>
    </div>
  )
}
