import React, { SVGAttributes } from 'react'

export const LifeBuoyIcon: React.FC<SVGAttributes<SVGSVGElement>> = (props) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="#0F1011" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.0002 30.6668C7.89999 30.6668 1.3335 24.1003 1.3335 16.0002C1.3335 7.89999 7.89999 1.3335 16.0002 1.3335C24.1003 1.3335 30.6668 7.89999 30.6668 16.0002C30.6668 24.1003 24.1003 30.6668 16.0002 30.6668ZM16.0002 28.0002C18.8335 28.0002 21.4375 27.0182 23.4904 25.376L19.6767 21.5623C18.6228 22.2603 17.3589 22.6668 16.0002 22.6668C14.6414 22.6668 13.3776 22.2603 12.3236 21.5623L8.50991 25.376C10.5628 27.0182 13.1668 28.0002 16.0002 28.0002ZM6.62429 23.4904C4.98213 21.4375 4.00016 18.8335 4.00016 16.0002C4.00016 13.1668 4.98213 10.5628 6.6243 8.50992L10.438 12.3236C9.74 13.3776 9.3335 14.6414 9.3335 16.0002C9.3335 17.3589 9.74 18.6228 10.438 19.6767L6.62429 23.4904ZM28.0002 16.0002C28.0002 18.8335 27.0182 21.4375 25.376 23.4904L21.5623 19.6767C22.2603 18.6228 22.6668 17.3589 22.6668 16.0002C22.6668 14.6414 22.2603 13.3776 21.5623 12.3236L25.376 8.50992C27.0182 10.5628 28.0002 13.1668 28.0002 16.0002ZM23.4904 6.6243L19.6767 10.438C18.6228 9.74 17.3589 9.3335 16.0002 9.3335C14.6414 9.3335 13.3776 9.74 12.3236 10.438L8.50992 6.6243C10.5628 4.98213 13.1668 4.00016 16.0002 4.00016C18.8335 4.00016 21.4375 4.98213 23.4904 6.6243ZM16.0002 20.0002C18.2093 20.0002 20.0002 18.2093 20.0002 16.0002C20.0002 13.791 18.2093 12.0002 16.0002 12.0002C13.791 12.0002 12.0002 13.791 12.0002 16.0002C12.0002 18.2093 13.791 20.0002 16.0002 20.0002Z"
    />
  </svg>
)
